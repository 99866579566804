import React from "react"
import logo from "../../assets/images/logox2.svg"

import { StyledLogo } from "./Logo.styled"

const Logo = () => {
  return (
    <StyledLogo>
      <img src={logo} alt="MedInvest" />
    </StyledLogo>
  )
}

export default Logo
